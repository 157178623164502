import { Component, OnInit } from '@angular/core';

import { FormGroup } from '@angular/forms';
import { finalize } from 'rxjs';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { DafInputDTO } from 'src/app/core/types/daf';
import { DafForm } from '../daf.form';
import { DafService } from '../daf.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-daf',
  templateUrl: './create-daf.component.html',
  styleUrls: ['./create-daf.component.scss']
})
export class CreateDafComponent implements OnInit {
  loading = false;
  dafForm: FormGroup;

  constructor(
    private dafService: DafService,
    private form: DafForm,
    private errorHandler: ErrorHandlerService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.dafForm = this.form.buildForm();
    this.form.fillTaxpayerName();
  }

  isDisabled() {
    return this.dafForm.invalid || this.loading;
  }

  addDafHandler() {
    if (this.dafForm.valid) {
      this.loading = true;
      const data: DafInputDTO = this.form.parseForm();
      
      this.dafService
        .createDaf(data)
        .pipe(finalize(() => this.loading = false))
        .subscribe({
          next: (data) => this.router.navigate(['dafs', data._id]),
          error: (error) => this.errorHandler.handle(error),
        });
    }
  }
}
